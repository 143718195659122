<template>
  <div class="container-fluid pr-lg-5 pl-xl-5" style="margin-top: 30px !important;">
    <div class="col-md-10 col-lg-8 col-xl-7 text-center p-0 mx-auto">
      <h1 class="display-4 font-secondary font-weight-semibold mb-4">
        Self Service Form
      </h1>
      <div class="lead text-muted mb-5">
        Please complete the form and press 'Submit' at bottom of the form. Our system will process your request immediately.
      </div>
      <b-form class="portfolio-block-padding-t px-0 mx-auto" @submit="sendRequest">
        <b-form-group label="TENANT ID" class="text-left">
          <b-input
              type="text"
              class="form-control form-control-lg bg-lighter border-1 px-3"
              v-model="request.tenantId"
              :state="state.tenantId"
          />
        </b-form-group>
        <div class="row mt-4">
          <div class="col-md-6">
            <b-form-group label="REQUEST TYPE" class="text-left">
              <b-form-radio-group class="form-control form-control-lg bg-lighter border-1 px-3"
              v-model="request.requestType"
              :state="state.requestType">
                <b-form-radio value="wl" class="mr-5">Allowlist</b-form-radio>
                <b-form-radio value="bl">Blocklist</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="TRAFFIC DIRECTION" class="text-left">
              <b-form-radio-group class="form-control form-control-lg bg-lighter border-1 px-3"
              v-model="request.trafficDirection"
              :state="state.trafficDirection">
                <b-form-radio value="in" class="mr-5">Inbound</b-form-radio>
                <b-form-radio value="out" class="mr-5">Outbound</b-form-radio>
                <b-form-radio value="both">Both</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
        </div>
        <b-form-group label="IP ADDRESS (single entry per line when adding multiple entries)" class="text-left mt-4">
          <b-textarea
              rows="6"
              class="form-control form-control-lg bg-lighter border-1 px-3"
              v-model="ipListTextarea"
              :state="state.ipList"
          ></b-textarea>
        </b-form-group>
        <b-btn variant="primary" size="lg" class="mt-4" type="submit" :disabled.sync="requestSending">
          <b-spinner v-show="requestSending" />
          SUBMIT REQUEST
        </b-btn>
      </b-form>
    </div>
  </div>
</template>

<script>
import csh from "@/csh";
import createSecurity from "../csl";

export default {
  name: "AppSelfService",
  data: () => ({
    requestSending: false,
    ipListTextarea: '',
    request: {
      tenantId: '',
      requestType: '',
      trafficDirection: '',
      ipList: [],
    },
    state: {
      tenantId: null,
      requestType: null,
      trafficDirection: null,
      ipList: null,
    }
  }),
  methods: {
    validateRequest() {
      this.state.tenantId = this.request.tenantId.trim() !== ''
      this.state.requestType = this.request.requestType.trim() !== ''
      this.state.trafficDirection = this.request.trafficDirection.trim() !== ''
      this.state.ipList = this.ipListTextarea.trim() !== ''

      return Object.values(this.state).every(Boolean)
    },
    showInformation(isError = false, infoText) {
      const classes = isError && 'bg-danger text-white' || 'bg-success text-white'
      this.$notify({
        group: 'notifications-bottom-center',
        type: classes,
        title: isError ? 'Error' : 'Information:',
        text: infoText
      })
    },
    sendRequest(evt) {
      evt.preventDefault()
      let self = this
      this.requestSending = true
      if (this.validateRequest()) {
        this.$recaptchaLoaded().then(() => {
          this.$recaptcha("self_service").then(() => {
            // console.log(token); // Will print the token; in .then change () to (token)
            this.request.ipList = this.ipListTextarea.split('\n');
            const currentUri = csh.avr2 + String.fromCharCode(47) + createSecurity() + csh.rqs0
            this.axios.post(csh.gcu() + currentUri,
                this.request
            ).then(response => {
              if (response.status === 201) {
                this.showInformation(false, 'Request successfully submitted! Check your inbox to authorize changes.')
                this.resetRequestFields()
              }
              this.requestSending = false
            }).catch(function (e) {
              console.log('error', e)
              self.showInformation(true, 'Request submitting failed! Try again later.')
              self.requestSending = false
            })
          });
        });
      } else {
        this.showInformation(true, 'Request not submitted! Correct the error fields and try again.')
        this.requestSending = false
      }
    },
    resetRequestFields () {
      this.request.tenantId = ''
      this.request.requestType = ''
      this.request.trafficDirection = ''
      this.request.ipList = []
      this.ipListTextarea = ''

      this.state.tenantId = null
      this.state.requestType = null
      this.state.trafficDirection = null
      this.state.ipList = null
    }
  }
};
</script>
